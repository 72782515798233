<template>
  <div>
    <b-card>
      <div class="d-flex justify-content-between">
        <div style="min-width: 20%; padding-right: 1.3rem">
          <b-button variant="primary" class="w-100" v-b-modal.add-event
            >Add Event</b-button
          >
        </div>
        <div>
          <full-calendar :config="config" :events="events" />
        </div>
      </div>
    </b-card>

    <b-modal id="add-event" size="md" hide-footer title="Event">
      <form @submit.prevent="addEvent">
        <b-form-group label="Title">
          <b-form-input id="title" v-model="title"></b-form-input>
        </b-form-group>
        <b-form-group label="Start">
          <flat-pickr
            v-model="start"
            class="form-control bg-white"
            :config="{ enableTime: true }"
          />
        </b-form-group>
        <b-form-group label="End">
          <flat-pickr
            v-model="end"
            class="form-control bg-white"
            :config="{ enableTime: true }"
          />
        </b-form-group>
        <b-form-group>
          <b-form-checkbox checked="true" name="check-button" switch inline>
            All Day
          </b-form-checkbox>
        </b-form-group>
        <b-form-group label="Event Url">
          <b-form-input id="event_url" v-model="url"></b-form-input>
        </b-form-group>
        <b-form-group label="Add Guests">
          <v-select
            v-model="guests"
            multiple
            label="name"
            :options="optionGuests"
          />
        </b-form-group>
        <b-form-group label="Location">
          <b-form-input id="location" v-model="location"></b-form-input>
        </b-form-group>
        <b-form-group label="Description">
          <b-form-textarea id="description" v-model="description" />
        </b-form-group>
        <div class="text-right">
          <b-button variant="primary" type="submit" size="sm">Submit</b-button>
        </div>
      </form>
    </b-modal>

    <b-modal
      v-for="event in eventsDetail"
      :key="event.id"
      :id="`detail_${event.id}`"
      size="md"
      hide-footer
      title="Event"
    >
      <form @submit.prevent="updateEvent(event)">
        <b-form-group label="Title">
          <b-form-input id="title" v-model="event.title"></b-form-input>
        </b-form-group>
        <b-form-group label="Start">
          <flat-pickr
            v-model="event.start"
            class="form-control bg-white"
            :config="{ enableTime: true, dateFormat: 'd F Y - H:i' }"
          />
        </b-form-group>
        <b-form-group label="End">
          <flat-pickr
            v-model="event.end"
            class="form-control bg-white"
            :config="{ enableTime: true, dateFormat: 'd F Y - H:i' }"
          />
        </b-form-group>
        <b-form-group>
          <b-form-checkbox checked="true" name="check-button" switch inline>
            All Day
          </b-form-checkbox>
        </b-form-group>
        <b-form-group label="Event Url">
          <b-form-input id="event_url" v-model="event.url"></b-form-input>
        </b-form-group>
        <b-form-group label="Add Guests">
          <v-select
            v-model="event.user_id"
            multiple
            label="name"
            :options="optionGuests"
          />
        </b-form-group>
        <b-form-group label="Location">
          <b-form-input
            id="location"
            v-model="event.extendedProps.location"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Description">
          <b-form-textarea
            id="description"
            v-model="event.extendedProps.notes"
          />
        </b-form-group>
        <div class="text-right">
          <b-button variant="primary" type="submit" size="sm">Submit</b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
// import "fullcalendar/dist/fullcalendar.min.css";
import FullCalendar from "vue-full-calendar/components/FullCalendar.vue";
export default {
  components: {
    FullCalendar,
  },
  data() {
    return {
      events: [],
      eventsDetail: [],
      optionGuests: [],
      config: {
        defaultView: "month",
        eventClick: this.handleDateClick,
      },
      title: "",
      start: "",
      end: "",
      url: "",
      location: "",
      guests: "",
      description: "",
    };
  },

  mounted() {
    this.callApi({
      url: "users/getAll",
      method: "GET",
      success: (res) => {
        res.result.data.map((item) => {
          this.optionGuests.push({
            id: item.id,
            name: item.name,
          });
        });

        this.optionGuests = this.optionGuests.filter((item) => item.id != 1);
      },
    });

    this.fetchEvents();
  },

  methods: {
    handleDateClick(event) {
      this.$bvModal.show(`detail_${event.id}`);
    },

    fetchEvents() {
      this.events = [];

      this.callApi({
        url: "events/fetch",
        method: "GET",
        success: (res) => {
          res.result.map((item) => {
            this.events.push({
              id: item.id,
              title: item.tittle,
              allDay: false,
              start: moment(item.start_date),
              end: moment(item.end_date),
              // url: item.event_url,
              extendedProps: {
                location: item.location,
                notes: item.description,
              },
            });

            this.eventsDetail.push({
              id: item.id,
              title: item.tittle,
              allDay: false,
              start: item.start_date,
              end: item.end_date,
              url: item.url,
              extendedProps: {
                location: item.location,
                notes: item.description,
              },
            });
          });
        },
      });
    },

    addEvent() {
      this.callApi({
        url: "events/create",
        method: "POST",
        data: {
          tittle: this.title,
          start_date: this.start,
          end_date: this.end,
          event_type: "HRA",
          event_url: this.url,
          location: this.location,
          user_id: this.guests[0].id,
          description: this.description,
        },
        success: (res) => {
          this.$bvModal.hide("add-event");
          this.fetchEvents();
        },
      });
    },

    updateEvent(data) {
      // this.callApi({
      //   url: `events/update/${this.hashid(data.id)}`,
      //   method: "POST",
      //   data: data,
      //   success: (res) => {
      //     this.$bvModal.hide("add-event");
      //     this.fetchEvents();
      //   },
      // });
      console.log(data);
    },
  },
};
</script>

<style>
@import "~fullcalendar/dist/fullcalendar.css";
</style>